/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_cognito_identity_pool_id": "eu-central-1:eeeb77ff-6fd4-4fa1-bd0b-9dd58dee9002",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_ssRvBBkmK",
    "aws_user_pools_web_client_id": "na54cfon4h6dgt9970ldusmk2",
    "oauth": {},
    "aws_cognito_login_mechanisms": [
        "PREFERRED_USERNAME"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_appsync_graphqlEndpoint": "https://jrfuu2zok5g6vodjr6kt2zaene.appsync-api.eu-central-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-central-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_mobile_analytics_app_id": "6418f9d81fd545ebb099e99909cf8036",
    "aws_mobile_analytics_app_region": "eu-central-1",
    "aws_user_files_s3_bucket": "profilepicturesmain-main",
    "aws_user_files_s3_bucket_region": "eu-central-1"
};


export default awsmobile;
